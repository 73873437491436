import React from 'react';

import {
    Box
} from "@mui/material/";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function DmarcTab(props) {
    /* Component exported including all the necessary to perform dmarc checks : form validation, request and renders */


    const pflagexplanation = () => {
        if (props.p == 'none') {
            return (<Box sx={{ color: 'error.main' }}>DMARC audit only policy. This means your domain can be spoofed by legitimate and unlegitimate senders.</Box>);
        }

        else if (props.p == 'quarantine') {
            return 'DMARC quarantine policy. This means your domain is protected against unlegitimate senders. Unauthenticated e-mails will be sent to the recipients spam folders'
        }

        else if (props.p == 'reject') {
            return 'DMARC reject policy. This means your domain is protected against unlegitimate senders. Unauthenticated e-mails will be rejected by the recipients antispam'
        }
        else {

            return (<Box sx={{ color: 'error.main' }}>Dmarc record as a flag that is not "none" nor "quarantine" nor "reject" and thus the DMARC policy can not be intepreted by e-mails recipients antispams</Box>);

        }
    }


    const spflagexplanation = () => {
        if (props.sp == 'none') {
            return (<Box sx={{ color: 'error.main' }}>DMARC audit only policy. This means your subdomains can be spoofed by legitimate and unlegitimate senders.</Box>);
        }

        else if (props.sp == 'quarantine') {
            return 'DMARC quarantine policy. This means your subdomains are protected against unlegitimate senders. Unauthenticated e-mails will be sent to the recipients spam folders'
        }

        else if (props.sp == 'reject') {
            return 'DMARC reject policy. This means your subdomains are protected against unlegitimate senders. Unauthenticated e-mails will be rejected by the recipients antispam'
        }
        else {

            return (<Box sx={{ color: 'error.main' }}>Dmarc record as a flag that is not "none" nor "quarantine" nor "reject" and thus the DMARC policy can not be intepreted by e-mails recipients antispams</Box>);

        }
    }

    if ((props.submitedform != false) && (props.dmarcrecordpresent) && (props.validdmarc != false)) {

        return (

            <React.Fragment>
                <br></br>
                <h5>{props.dmarcrecord}</h5>

                <TableContainer component={Paper}>
                    <Table sx={{ Width: '50%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>DMARC Flags in components</b></TableCell>
                                <TableCell align="center"><b>Value</b></TableCell>
                                <TableCell align="center"><b>Explanation</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            <TableRow

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <b>Location of applied Dmarc record</b>
                                </TableCell>
                                <TableCell align="center">_dmarc.{props.dmarcrecordlocation}</TableCell>
                                <TableCell align="center">This dmarc record is hosted by these DNS servers:

                                    {(props.ns).map((nss, i) => (
                                        <div key={i}>
                                            {nss}

                                        </div>

                                    ))}</TableCell>
                            </TableRow>

                            <TableRow

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <b>P flag</b>
                                </TableCell>
                                <TableCell align="center">{props.p}</TableCell>
                                <TableCell align="center">{pflagexplanation()}</TableCell>
                            </TableRow>

                            <TableRow

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <b>SP flag</b>
                                </TableCell>
                                <TableCell align="center">{props.sp}</TableCell>
                                <TableCell align="center">{spflagexplanation()}</TableCell>
                            </TableRow>

                            <TableRow

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <b>PCT Flag</b>
                                </TableCell>
                                <TableCell align="center">{props.pct}</TableCell>
                                <TableCell align="center">The DMARC policy P={props.p} & SP={props.sp} is applied on {props.pct}% of e-mails sent on behalf of this domain</TableCell>
                            </TableRow>

                            <TableRow

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <b>RUA flag</b>
                                </TableCell>
                                <TableCell align="center">      {(props.rua).map((ruas, i) => (
                                    <div key={i}>
                                        {ruas['address']}

                                    </div>

                                ))}</TableCell>
                                <TableCell align="center">This is the e-mail adress on which the administrators will receive DMARC aggregate reports</TableCell>
                            </TableRow>

                            <TableRow

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <b>RUF flag</b>
                                </TableCell>
                                <TableCell align="center">

                                    {(props.ruf).map((rufs, i) => (
                                        <div key={i}>
                                            {rufs['address']}

                                        </div>

                                    ))}

                                </TableCell>
                                <TableCell align="center">This is the e-mail adress on which the administrators will receive DMARC forensic reports</TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>

        )
    } else if ((props.dmarcrecordpresent == false) && (props.submitedform == true)) {

        return (

            <React.Fragment>
                <br></br>
                <h4>No DMARC record</h4>
            </React.Fragment>

        );
    }
    else if (props.submitedform == false) {

        return (

            <React.Fragment>
            </React.Fragment>

        );
    }

    else if (props.validdmarc == false) {

        return (

            <React.Fragment>
            <br></br>
            <h5>{props.dmarcrecord}</h5>
            <br></br>
            <TableContainer component={Paper}>
                <Table sx={{ Width: '50%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Error</b></TableCell>
                            <TableCell align="center"><Box sx={{ color: 'error.main' }}><b>{props.errordmarc}</b></Box></TableCell>
                        </TableRow>
                    </TableHead>

                </Table>
            </TableContainer>
        </React.Fragment>

        );
    }




}



export default DmarcTab;