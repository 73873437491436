import React from "react";

function MenuLogoDmarc() {
  return (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      baseProfile="tiny"
      version="1.2"
      viewBox="0 0 283.5 283.5"
      xmlSpace="preserve"
    >
      <radialGradient
        id="SVGID_1_"
        cx="78.33"
        cy="69.006"
        r="229.088"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#E5E2DF"></stop>
        <stop offset="0" stopColor="#F7F6F5"></stop>
        <stop offset="0" stopColor="#FFF"></stop>
        <stop offset="0.407" stopColor="#ECEDED"></stop>
        <stop offset="0.63" stopColor="#E2E3E3"></stop>
        <stop offset="0.878" stopColor="#BFC0C1"></stop>
        <stop offset="1" stopColor="#ADAEB0"></stop>
      </radialGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M28.3 265.4l22.6-83.3s-25-58.3-25-105.9c0 0 66.7-72.6 122.6-60.7s108.3 56 108.3 56 9.5 144-115.5 192.9L77 212.6l-48.7 52.8z"
      ></path>
      <path
        fill="#204497"
        d="M196.4 87l.4-.4-13.1.1h-94c-1.6 0-3.1.2-4.6.7h-.9l.2.2c-6.4 2.2-10.9 8.2-10.9 15.3v56.6c0 8.9 7.3 16.2 16.2 16.2H193c8.9 0 16.2-7.3 16.2-16.2v-56.6c.1-7.9-5.4-14.4-12.8-15.9zm-3.3 11c2.7 0 4.8 2.2 4.8 4.8v56.6c0 .9-.3 1.7-.7 2.5L161 125.2 186.2 98h6.9zm-22.5.1l-30.1 32.6-30.1-32.2 60.2-.4zm-30 49.2l12.7-13.8 30.2 30.7h-86l30.2-30.6 12.9 13.7zm-20.7-22l-34.8 35.3c-.1-.4-.2-.8-.2-1.2v-56.6c0-2.7 2.2-4.8 4.8-4.8h4.6l25.6 27.3z"
      ></path>
      <path
        fill="#BE4588"
        d="M265.5 72.1l-.2-2.4-1.6-1.8c-46.8-50.5-117.4-57-120.4-57.2h-1.2c-3 .2-73.6 6.7-120.4 57.2L20 69.7l-.2 2.4c-.1 1.3-2.9 33.3 10.4 73.9 3.2 9.9 7.7 20.9 13.6 32.3l5.2-22.6c-1.8-4.4-3.5-8.9-5-13.5-10.6-31.9-10.4-58.6-10.1-66.4 40.7-42.5 102-50.1 108.7-50.8 6.7.7 68 8.4 108.8 50.8.3 7.9.5 34.5-10.1 66.4-16.4 49.5-49.6 88.2-98.7 115.1-21.2-11.6-39.4-25.4-54.6-41.3l-10.4 9.5c15.8 16.6 35.9 32.6 61.6 46.2l3.3 1.8 3.3-1.8c68-35.9 96.9-88.2 109.1-125.7 13.5-40.6 10.7-72.6 10.6-73.9z"
      ></path>
      <path
        fill="#BE4588"
        d="M42.6 241l12.6-43.5c-4.3-6.5-8.1-12.9-11.4-19.2l-22.6 99 56.5-51.8c-3.4-3.5-6.5-7.1-9.5-10.7L42.6 241z"
      ></path>
      <path
        fill="#BE4588"
        d="M78.1 204.7l-9.9 10.2c3 3.6 6.1 7.2 9.5 10.7l10.4-9.5c-3.5-3.7-6.8-7.5-10-11.4zM60.5 179.3c-4.3-7.5-8.2-15.4-11.5-23.6l-5.2 22.6c3.3 6.3 7.1 12.7 11.4 19.2l5.3-18.2z"
      ></path>
    </svg>
  );
}

export default MenuLogoDmarc;
