import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
    TextField,
    Button,
} from "@mui/material/";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import CircularProgress from '@mui/material/CircularProgress';
const MyPopper = ({ style, ...props }) => (
  <Popper style={{ zIndex: 100, ...style }} {...props}/>
)

function CheckForm(props) {
 
    let navigate = useNavigate(); {/* after valication of the form for redirection to the same page but with the "domain" parameter in the URL */ }
    const [domainform, setDomainform] = useState(props.domaininurl);
    const [selectorform, setSelectorform] = useState(''); /* Dkim selector in form*/

    const submit = async (e) => {
        e.preventDefault();

        if(selectorform != '') {
        navigate("/" + options[selectedIndex] + "?domain=" + selectorform + "._domainkey." + domainform); /* after valication of the form for redirection to the same page but with the "domain" parameter in the URL */
    }
    else{
    var indd=selectedIndex;
if(selectedIndex==-1){
indd=0;
setSelectedIndex(0);
}
navigate("/" + options[indd] + "?domain="+ domainform); /* after valication of the form for redirection to the same page but with the "domain" parameter in the URL */
    } 
        
        setSelectorform('');
    }

    /* start submit button with multiple choice */

    const options = ['DMARC record', 'SPF record', 'MX record','NS record','TXT record','DKIM record','A record','Reverse DNS','BIMI record', 'IP blacklist','Domain blacklist'];


/* to select the index of options in url path */
   


    const cleanedoptioninpath = props.pathname.split('%20').join(' ').split('/').join('');
    const constindexinpath = options.indexOf(cleanedoptioninpath);
   



    const [selectedIndex, setSelectedIndex] = React.useState(constindexinpath);

    
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);




    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };


    return (
                <form sx={{ zIndex: 'tooltip' }}onSubmit={submit}>
                   
                   { 
                    (options[selectedIndex] == "DKIM record") &&

                    <TextField
                    style={{ width: "75%", margin: "5px" }}
                    type="text"
                    label={'Enter DKIM selector'}
                    variant="outlined"
                    onChange={e => setSelectorform(e.target.value)}
                /> 
                }

                   
                    <TextField
                        style={{ width: "75%", margin: "5px" }}
                        type="text"
                        label={'Enter domain or IP'}
                        variant="outlined"
                        value={domainform}
                        onChange={e => setDomainform(e.target.value)}
                    />

                    <br />  <br />

                    <ButtonGroup  ref={anchorRef} aria-label="split button">
                        <Button variant="basicPink" type="submit">{options[selectedIndex]}  {(selectedIndex == -1) && <>Please select a </>}DNS Check{props.loading && (
                            <Box paddingLeft={1}><CircularProgress  color="inherit" size={20} /></Box>)} </Button>


                        <Button
                            size="small"
                            variant="basicPink"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <MyPopper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        sx={{zIndex:4}}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow

                                {...TransitionProps}
                                style={{zIndex:4,
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper sx={{zIndex:4}}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu">
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </MyPopper>
                </form>

    );
}

export default CheckForm;