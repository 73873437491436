import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Spflookup from './spflookup';
import {
    Box
} from "@mui/material/";




function SpfTab(props) {




    if ((props.submitedform != false) && (props.spfrecordpresent)) {
        
        console.log(props.spfdnslookups);
        console.log(props.spfrecord);
        
        return (

            <React.Fragment>
                <br></br>
                <h5>{props.spfrecord} <br></br> </h5>



                { 
                    props.spfdnslookups != null ?

                   <div><br></br> (Total DNS lookups, including nested lookups : {props.spfdnslookups})</div> :null
                }           



                { 
                    props.spferror != null ?

                   <Box sx={{ color: 'error.main' }}><b>Error : {props.spferror}</b> </Box>:null
                }                

               {
                props.spfwarnings.map((warning, i) => {
                    return (
                        <Box sx={{ color: 'error.main' }} key={i}>
                        <b>{warning}</b>
                        </Box>
                    )

                })
               }

         

                { 
                    props.includes.length >= 1 ?

                    <Spflookup includes={props.includes}/>:null
                }

            </React.Fragment>

        )
    } else if ((props.spfrecordpresent == false) && (props.submitedform == true)) {

        return (

            <React.Fragment>
                <br></br>
                <h4>No SPF record</h4>
            </React.Fragment>

        );
    }
    else if (props.submitedform == false) {

        return (

            <React.Fragment>
            </React.Fragment>

        );
    }
}

export default SpfTab;