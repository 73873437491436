import * as React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuLogoDmarc from '../MenuLogoDmarc.js';

const onClickdmarcsite = () => {
  window.location.assign('https://www.dmarc-expert.com/why-dmarc/');
}

const Nav = () => {


  return (
    <AppBar style={{ background: '#18185d' }} position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
            <Box  style={{ height: 53, width: 50 }} >
        <MenuLogoDmarc  />
        </Box>


          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{p:2, mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            DNS toolbox
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Box  paddingTop={2}>
          <Button variant="basicPink" onClick={onClickdmarcsite} >
          Check
          </Button>
          </Box>
          
              <Button
                onClick={onClickdmarcsite}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
             if DMARC is properly configured on your domains, to prevent impersonations and increase deliverability.
              </Button>
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Nav;
