import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import red from '@mui/material/colors/red';
import { StyledEngineProvider } from "@mui/material/styles";


axios.defaults.baseURL = 'https://apidnstoolprod.dmarc-expert.com/api/';
axios.defaults.withCredentials = true;




const dtheme = createTheme({
palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    neutral: {
      main: '#e2e4e7',

    },greyb: {
      main: '#eaedf5',

    },
    blueauth: {
      main: '#7093ed',

    },
  },
typography: {
    bb: {
      color:  '#c04990',
       fontFamily:"Inter",
    },
    cc: {
      color:  '#000000',
       fontFamily:"InterBold",
    },CommentTitle: {
      color:  '#a2a6af',
       fontFamily:"InterBold",
       fontSize: 14,
       fontWeight: 'bold'
    },
    title: {
      color:  '#18185d',
       fontFamily:"InterBold",
       fontSize: 32,
       fontWeight:"bold"
    },
    subtitle: {
      color:  '#18185d',
       fontFamily:"InterBold",
       fontSize: 22,
       fontWeight:"bold"
    },
    standard: {
      color:  '#a2a6af',
       fontFamily:"InterBold",


    },
    standardblack: {
      color:  '#000000',
       fontFamily:"InterBold",
       fontWeight:"bold"


    },
    domain: {
      color:  '#000000',
       fontFamily:"InterBold",
       textTransform: 'uppercase',
       fontSize: 14,
        fontWeight: 'bold',

    },
    domainTag: {
      color:  '#b6b6b6',
      textTransform: 'uppercase',
       fontFamily:"InterBold",
       fontSize: 13,
        fontWeight: 'bold',

    },stbold: {
      color:  '#000000',
       fontFamily:"InterBold",

       fontSize: 14,
        fontWeight: 'bold',

    },
    st: {
      color:  '#000000',
       fontFamily:"InterBold",

       fontSize: 14,


    },
    stboldgrey: {
      color:  '#b6b6b6',
       fontFamily:"InterBold",
       textTransform: 'uppercase',
       fontSize: 14,
        fontWeight: 'bold',

    },
  },
  components: {MuiTextField: {
      defaultProps: {
        variant: "outlined",
        fullWidth: true,
        sx: { mb: 2 }
      },
      styleOverrides: {
        root: {
          marginTop: 40,

          "& label": {
            // set some styles for the label if need it
            color:"#a2a6af"
          },
          "& legend": {
            // set some styles for the legend if need it
          },

          // this is styles for the new variants
          "&.subvarianthovered": {
            "& fieldset": {
              border: "none"
            }
          }
        }
      }
    },
  MuiTypography:{
    variants:[
    {
    props: { variant: 'bxb' },
          style: {
          color:  '#757575',
                fontFamily:"Inter",
            }

    }

    ]

  },
    MuiButton: {
      variants: [
        {
          props: { variant: 'basicPink' },
          style: {
            textTransform: 'none',
             backgroundColor: "#c04990",
             color:"#ffffff",
             '&:hover': {
                  backgroundColor:'#c10d78',
                  color:  '#fff',
                    }
            }
        },
        {
          props: { variant: 'basicClear' },
          style: {
            textTransform: 'none',

             color:"#b6b6b6",
             '&:hover': {

                  color:  '#757575',
                    }
            }
        },
        {
          props: { variant: 'basicBlack' },
          style: {
            textTransform: 'none',

             color:"#000000",
             '&:hover': {

                  color:  '#757575',
                    }
            }
        },
        {
          props: { variant: 'blueDisplay' },
          style: {
            textTransform: 'none',

            border: `2px solid #888cae`,

             color:"#888cae",
             '&:hover': {

                  color:  '#888cae',
                    }
            }
        },{
          props: { variant: 'basicDisplay' },
          style: {
            textTransform: 'none',
            border: `2px solid #bfbfbf`,
             color:"#000000",
             fontWeight:"bold",
             '&:hover': {

                  color:  '#888cae',
                    }
            }
        },{
          props: { variant: 'detail' },
          style: {
            textTransform: 'none',

             color:"#000000",
             '&:hover': {

                  color:  '#888cae',
                    }
            }
        },{
          props: { variant: 'pinkDisplay' },
          style: {
            textTransform: 'none',
            border: `2px solid #c04990`,
             color:"#000000",
             fontWeight:"bold",
             '&:hover': {

                  color:  '#888cae',
                    }
            }
        },{
          props: { variant: 'addGrey' },
          style: {
            textTransform: 'none',
             //backgroundColor: "#bfbfbf",
             color:"#bfbfbf", fontWeight: 'bold',
             '&:hover': {
                  backgroundColor:'#bfbfbf',
                  color:  '#fff',
                    }
            }
        },{
          props: { variant: 'addBlue' },
          style: {
            textTransform: 'none',
             backgroundColor: "#3f6fff",
             color:"#ffffff",
             '&:hover': {
                  backgroundColor:'#003df6',
                  color:  '#fff',
                    }
            }
        },
        {
          props: { variant: 'statusBlue' },
          style: {
            textTransform: 'none',
            borderRadius: 5,

             backgroundColor: "#3f6fff",
             color:"#ffffff",
             '&:hover': {
                  backgroundColor:'#3f6fff',
                  color:  '#fff',
                    }
            }
        },
        {
          props: { variant: 'statusYellow' },
          style: {
            textTransform: 'none',
            borderRadius: 5,

             backgroundColor: "#e0cf25",
             color:"#ffffff",
             '&:hover': {
                  backgroundColor:'#e0cf25',
                  color:  '#fff',
                    }
            }
        },
        {
          props: { variant: 'statusRed' },
          style: {
            textTransform: 'none',
            borderRadius: 5,

             backgroundColor: "#e0254d",
             color:"#ffffff",
             '&:hover': {
                  backgroundColor:'#e0254d',
                  color:  '#fff',
                    }
            }
        },{
          props: { variant: 'statusGrey' },
          style: {
            textTransform: 'none',
            borderRadius: 5,
             backgroundColor: "#bfbfbf",
             color:"#ffffff",
             '&:hover': {
                  backgroundColor:'#bfbfbf',
                  color:  '#fff',
                    }
            }
        },{
          props: { variant: 'statusBlack' },
          style: {
            textTransform: 'none',
            borderRadius: 5,
             backgroundColor: "#000000",
             color:"#FFFFFF",
             '&:hover': {
                  backgroundColor:'#000000',
                  color:  '#fff',
                    }
            }
        }
        ,{
          props: { variant: 'statusGreen' },
          style: {
            textTransform: 'none',
            borderRadius: 5,
             backgroundColor: "#43b672",
             color:"#ffffff",
             '&:hover': {
                  backgroundColor:'#43b672',
                  color:  '#fff',
                    }
            }
        },{
          props: { variant: 'statusOrange' },
          style: {
            textTransform: 'none',
            borderRadius: 5,
             backgroundColor: "#ea5c13",
             color:"#ffffff",
             '&:hover': {
                  backgroundColor:'#ea5c13',
                  color:  '#fff',
                    }
            }
        },


         { props: { variant: 'mdashed', color: 'secondary' },
          style: {
            border: `4px dashed ${red[500]}`,
          },
        },
        {
          props: { variant: 'dashed', color: 'secondary' },
          style: {

            color: "#20b2aa",
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'dashed' },
          style: {


          },
        }
      ],
    },
     MuiTextField: {
      variants: [
        {
          props: { variant: 'invisible' },
          style: {
 border: `0px`,


          },
        }
      ],
    },
  },
});



ReactDOM.render(
 <StyledEngineProvider injectFirst><ThemeProvider theme={dtheme}>
 <div heigh="100vh">
    <App />
    </div>
    </ThemeProvider></StyledEngineProvider>
 ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
