import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



function Subspflookup(props) {


    if (props.includes.length >= 1 ) {
        console.log(props.includes);
        return (

            <React.Fragment>

                {(props.includes).map((include, i) => (

                    <div zIndex={-1} key={i}>


                        <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                         <> <Typography><b>{include['domain']}</b>  (DNS lookups : {include['dns_lookups'] })</Typography>
                          {include['warnings'].length >= 1 && <Typography color="error"> ({include['warnings'].length} erreurs)</Typography>}</>
                        </AccordionSummary>
                        <AccordionDetails>


                          <Typography>

                          {include['record']}
                      
                          </Typography>

                        </AccordionDetails>
                      </Accordion>
                      <>{
                      include.parsed.include?.length >= 1  &&  <Subspflookup includes={include.parsed.include}/>
                    }
                      {include["warnings"].map((warning, i) => {
                            return (
                              <Typography key={i} color="error">
                                <b>{warning}</b>
                                </Typography>
                            )

                        })}</>
                        </div>


                 




                    

                ))}
                

            </React.Fragment>

        )
    }
       
}

export default Subspflookup;