import React from 'react';
import './App.css';
import Nav from './components/Nav'
import ChecksLogics from './components/checks/checkslogics';
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";


function App() {
  return (

    <div heigh="100vh" className="App">



      <Nav />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ChecksLogics />} />
          <Route path="/DMARC%20record" element={<ChecksLogics />} />
          <Route path="/SPF%20record" element={<ChecksLogics />} />
          <Route path="/MX%20record" element={<ChecksLogics />} />
          <Route path="/NS%20record" element={<ChecksLogics />} />
          <Route path="/TXT%20record" element={<ChecksLogics />} />
          <Route path="/DKIM%20record" element={<ChecksLogics />} />
          <Route path="/A%20record" element={<ChecksLogics />} />
          <Route path="/Reverse%20dns" element={<ChecksLogics />} />
          <Route path="/BIMI%20record" element={<ChecksLogics />} />
          <Route path="/IP%20blacklist" element={<ChecksLogics />} />
          <Route path="/Domain%20blacklist" element={<ChecksLogics />} />
        </Routes>
      </BrowserRouter>


    </div>




  );
}

export default App;
